<template>
  <div style="width: 100%;" class="custom-container">
    <v-row dense no-gutters>
      <v-col cols="12">
        <Table
          :key="tableKey"
          :items="processes"
          :loading="loading"
          @activeOnly="activeOnlyFunction($event)"
          @includeSystem="includeSystemFunction($event)"
          @changeStatus="checkActiveTriggers($event)"
          @fetchProcesses="fetchProcesses($event)"
          @copyCreate="copyCreate($event)"
        ></Table>
      </v-col>
    </v-row>
    <v-dialog v-if="yesNoShow" v-model="yesNoShow" max-width="55%">
      <yes-no-modal
        :title="$lang.labels.activeTriggers"
        :action-text="textForRunModal"
        :handle-no="true"
        @submit="deactivateTriggers()"
        @noSelected="changeStatus()"
        @closeDialog="yesNoShow = false; processToDisable = null; triggerListToDeactivate = []"
      ></yes-no-modal>
    </v-dialog>
  </div>
</template>

<script>
import Table from './ProcessesDatatable'
import {
  getProcessesUsingGET as getProcesses,
  disableProcessUsingGET as disableProcess,
  enableProcessUsingGET as enableProcess,
  getTriggersByProcessIdUsingGET as getTriggersByProcessId,
  disableTriggerCronUsingGET as disableCron,
  disableTriggerRestUsingGET as disableRest,
  disableTriggerEventHandlerUsingGET as disableEventHandler,
  disableTriggerMessagingUsingGET as disableMessaging
} from '@/utils/api'
import YesNoModal from '@/components/ui/modals/YesNoModal.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    YesNoModal,
    Table
  },
  data() {
    return {
      err: '',
      success: '',
      activeOnly: true,
      includeSystem: false,
      tableKey: 0,
      loading: false,
      processes: { items: [], meta: {} },
      yesNoShow: false,
      processToDisable: null,
      textForRunModal: '',
      triggerListToDeactivate: []
    }
  },
  computed: {},
  created() {
    if (this.$route.params && this.$route.params.message) {
      this.addSnackbar({
        message: this.$route.params.message,
        timeout: 5000,
        color: 'success'
      })
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    copyCreate(item) {
      this.$router.push({ name: 'processCreate', params: { copy: { id: item.id } } })
    },
    includeSystemFunction(event) {
      this.includeSystem = event.includeSystem

      this.fetchProcesses(event.options)
    },
    activeOnlyFunction(event) {
      this.activeOnly = event.activeOnly
      this.includeSystem = event.includeSystem

      this.fetchProcesses(event.options)
    },
    fetchProcesses(options) {
      this.loading = true

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      if (options?.options?.sortDesc && options.options.sortDesc.length > 0) obj.orderDirection = options.options.sortDesc[0] ? 'DESC' : 'ASC'

      if (options && options.options && options.options.sortBy && options.options.sortBy.length > 0) {
        obj.orderField = options.options.sortBy[0]
      }

      if (options && options.search && options.search.length > 1) {
        obj.name = options.search
      }

      if (options && options.permissionType) {
        obj.permissionType = options.permissionType
      }

      if (this.activeOnly) obj.status = 'ACTIVE'

      if (!this.includeSystem) obj.isSystem = false

      getProcesses(obj)
        .then((res) => {
          this.processes = res.data.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    },
    async checkActiveTriggers(event) {
      this.processToDisable = event

      if (event.status === 'INACTIVE') {
        this.changeStatus()

        return
      }

      getTriggersByProcessId({ id: event.id, request: { page: 1, size: 10000 } })
        .then((res) => {
          if (res.data.data.items.length > 0) {
            this.yesNoShow = true
            this.triggerListToDeactivate = res.data.data.items

            this.textForRunModal = res.data.data.items.map((x) => x.name).join(',\n')
          } else {
            this.changeStatus()
          }
        })
        .catch((error) => {

          this.addSnackbar({
            message: error,
            timeout: 5000,
            color: 'error'
          })
        })
    },
    deactivateSingleTrigger(trigger) {
      new Promise((resolve, reject) => {
        switch (trigger.type) {
        case 'TRIGGER_CRON':
          disableCron({ id: trigger.id })
            .then(() => {
              resolve(true)
            })
            .catch(() => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(true)
            })
          break
        case 'TRIGGER_REST':
          disableRest({ id: trigger.id })
            .then(() => {
              resolve(true)
            })
            .catch(() => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(true)
            })
          break
        case 'TRIGGER_EVENT_HANDLER':
          disableEventHandler({ id: trigger.id })
            .then(() => {
              resolve(true)
            })
            .catch(() => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(true)
            })
          break
        case 'TRIGGER_MESSAGING':
          disableMessaging({ id: trigger.id })
            .then(() => {
              resolve(true)
            })
            .catch(() => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject(true)
            })
          break
        }
      })
    },
    deactivateTriggers() {
      const loopRes = this.triggerListToDeactivate.map((x) => {
        return this.deactivateSingleTrigger(x)
      })

      Promise.all(loopRes).then(() => {
        this.changeStatus()
      })
    },
    async changeStatus () {
      try {
        const res = this.processToDisable.status === 'ACTIVE' ? await disableProcess({ id: this.processToDisable.id }) : await enableProcess({ id: this.processToDisable.id })

        if (res.status !== 200) {

          this.addSnackbar({
            message: this.$lang.errors.processStatusUpdate,
            timeout: 5000,
            color: 'error'
          })

          return
        }
        const successMessage = this.processToDisable.status !== 'ACTIVE' ? this.$lang.success.processActivated : this.$lang.success.processDeactivated

        this.addSnackbar({
          message: successMessage,
          timeout: 5000,
          color: 'success'
        })

        const changedItem = this.processes.items.find((x) => x.id === this.processToDisable.id)

        if (changedItem) this.processes.items.find((x) => x.id === this.processToDisable.id).status = res.data.data.status

        this.yesNoShow = false
        this.processToDisable = null
        this.triggerListToDeactivate = []

      } catch (err) {
        this.addSnackbar({
          message: error,
          timeout: 5000,
          color: 'error'
        })
      }
    }
  }
}
</script>
